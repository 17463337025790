<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title>
        {{ title }}
      </b-card-title>
    </b-card-header>
    <hr style="margin-top: -0.5rem" />
    <b-card-body>
      <b-alert variant="warning" show>
        <div class="alert-body">
          <center>
            <span class="mx-auto">
              <strong>{{ subtitle }} </strong>
            </span>
          </center>
        </div>
      </b-alert>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BAlert,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
} from "bootstrap-vue";

export default {
  name: "alertWarning",
  components: {
    BAlert,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  props: {
    title: String,
    subtitle: String,
  },
};
</script>