<template>
  <b-card no-body class="h-100">
    <b-card-header class="mb-3">
      <b-card-title>
        <b-card-text>Unggahan Terbaru di {{ provinsi.name }}</b-card-text>
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <div v-for="items in item_pages" :key="items.id" class="shadow rounded">
        <!-- NEWS CARD COMPONENT -->
        <newsMedsosCard class="shadow rounded" />
      </div>
      <!-- PAGINATION -->
      <b-pagination
        v-model="current_page"
        :per-page="per_page"
        :total-rows="items.length"
        align="right"
        size="sm"
        class="mt-2"
      >
      </b-pagination>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BPagination,
  BCardText,
} from "bootstrap-vue";
import newsMedsosCard from "@/components/usable/newsMedsosCard.vue";
export default {
  name: "cardUnggahanTerbaru",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BPagination,
    BCardText,
    newsMedsosCard,
  },

  props: ["provinsi"],

  data: () => ({
    currentPage: 1,
    current_page: 1,
    per_page: 2,
    item_pages: [],
    items: [
      {
        id: 1,
      },
      {
        id: 2,
      },
      {
        id: 3,
      },
      {
        id: 4,
      },
      {
        id: 5,
      },
      {
        id: 6,
      },
      {
        id: 7,
      },
      {
        id: 8,
      },
      {
        id: 9,
      },
      {
        id: 10,
      },
    ],
  }),

  created() {
    this.updateItemPages();
  },

  methods: {
    updatePage(page_number) {
      this.current_page = page_number;
      this.updateItemPages();
    },

    updateItemPages() {
      this.item_pages = this.items.slice(
        (this.current_page - 1) * this.per_page,
        (this.current_page - 1) * this.per_page + this.per_page
      );
      if (this.item_pages.length == 0 && this.current_pages == 0) {
        this.updatePage(this.current_pages - 1);
      }
    },
  },
};
</script>
<style scoped>
.dark-layout * .shadow {
  box-shadow: 0.5px 0.5px 0.5px 1px #d0d2d6 !important;
}
</style>