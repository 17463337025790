<template>
  <b-card no-body class="h-100">
    <b-card-header class="mb-2">
      <b-card-title>
        <b-card-text>Akun Terpopuler di {{ provinsi.name }}</b-card-text>
      </b-card-title>
      <!-- SEARCH FORM -->
      <b-input-group class="input-group-merge" style="width: 35%">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" class="text-primary" />
        </b-input-group-prepend>
        <b-form-input placeholder="Cari" v-model="table_data.filter" />
      </b-input-group>
    </b-card-header>
    <!-- TABLE COMPONENT -->
    <dataTable :data="table_data" />
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormInput,
  BFormSelect,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import dataTable from "@/components/usable/dataTable.vue";

export default {
  name: "cardAkunTerpopuler",
  components: {
    BAvatar,
    BBadge,
    BPagination,
    BFormInput,
    BFormSelect,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardText,
    BInputGroup,
    BInputGroupPrepend,
    dataTable,
  },

  props: ["provinsi"],

  data() {
    return {
      table_data: {
        row: 10,
        filter: null,
        fields: [
          {
            label: "Platform",
            key: "platform",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "20%" },
          },
          {
            label: "Name",
            key: "name",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
          },
          {
            label: "Post",
            key: "post",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "20%", paddingRight: "2rem" },
            sortable: true,
          },
        ],
        items: [
          {
            avatar: require("@/assets/images/avatars/10-small.png"),
            platform: "twitter",
            name: "Alvin",
            post: 1001,
            pers: true,
          },
          {
            avatar: require("@/assets/images/avatars/10-small.png"),
            platform: "facebook",
            name: "Zakiyah",
            post: 1001,
            pers: true,
          },
          {
            avatar: require("@/assets/images/avatars/10-small.png"),
            platform: "instagram",
            name: "Zakaria",
            post: 1001,
            pers: false,
          },
          {
            avatar: require("@/assets/images/avatars/10-small.png"),
            platform: "youtube",
            name: "Jhon Alfabhet",
            post: 1001,
            pers: false,
          },
          {
            avatar: require("@/assets/images/avatars/10-small.png"),
            platform: "linkedin",
            name: "Kristina Agustin",
            post: 1001,
            pers: true,
          },
          {
            avatar: require("@/assets/images/avatars/10-small.png"),
            platform: "tiktok",
            name: "Kopral Sulaiman",
            post: 1001,
            pers: false,
          },
          {
            avatar: require("@/assets/images/avatars/10-small.png"),
            platform: "blog",
            name: "Ucup Setiabudi",
            post: 1001,
            pers: true,
          },
          {
            avatar: require("@/assets/images/avatars/10-small.png"),
            platform: "forum",
            name: "Yusuf Al fatih",
            post: 1001,
            pers: true,
          },
          {
            avatar: require("@/assets/images/avatars/10-small.png"),
            platform: "facebook",
            name: "Rizky Putra",
            post: 1001,
            pers: true,
          },
          {
            avatar: require("@/assets/images/avatars/10-small.png"),
            platform: "twitter",
            name: "Iqbal",
            post: 1001,
            pers: true,
          },
        ],
      },
    };
  },
};
</script>
