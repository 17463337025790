<template>
    <div>
      <b-card>
        <!-- SWITCH BUTTON MEDIA KONVENSIONAL IS ACTIVE -->
        <div class="d-flex" v-if="media === 'konvensional'">
          <b-button
            variant="primary"
            class="mr-2"
            v-on:click="media = 'konvensional'"
          >
            Media Konvensional
          </b-button>
          <b-button variant="flat" v-on:click="media = 'sosial'">
            Media Sosial
          </b-button>
        </div>
  
        <!-- SWITCH BUTTON MEDIA SOSIAL IS ACTIVE -->
        <div class="d-flex" v-else-if="media === 'sosial'">
          <b-button
            variant="flat"
            class="mr-2"
            v-on:click="media = 'konvensional'"
          >
            Media Konvensional
          </b-button>
          <b-button variant="primary" v-on:click="media = 'sosial'">
            Media Sosial
          </b-button>
        </div>
      </b-card>
  
      <!-- CONTENT OF MEDIA KONVENSIONAL -->
      <div v-if="media === 'konvensional'">
        <bubbleMaps :media="'Konvensional'" />
      </div>
  
      <!-- CONTENT OF MEDIA SOSIAL -->
      <div v-else-if="media === 'sosial'">
        <bubbleMaps :media="'Sosial'" />
      </div>
    </div>
  </template>
  <script>
  import { BCard, BButton } from "bootstrap-vue";
  import bubbleMaps from "@/components/analytic/maps/bubbleMaps.vue";
  
  export default {
    name: "Maps",
    components: {
      BCard,
      BButton,
      bubbleMaps,
    },
  
    data() {
      return {
        title: "Maps | Online Media Monitoring",
        media: "konvensional",
      };
    },
    
    created() {
      document.title = this.title;
    },
  };
  </script>