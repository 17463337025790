<template>
  <div>
    <b-card no-body class="h-100">
      <b-card-header class="mb-2">
        <b-card-title>
          Jumlah Mention Media {{ media }} per Provinsi di Indonesia
          <b-card-text class="font-small-3" v-if="media == 'Konvensional'">
            Peta Sebaran Mention per 27 Jul 2022 00.00 hingga 2 Aug 2022 23.59
          </b-card-text>
          <b-card-text class="font-small-3" v-else>
            Peta Sebaran Mention Twitter per 27 Jul 2022 00.00 hingga 2 Aug 2022
            23.59
          </b-card-text>
        </b-card-title>

        <!-- EXPORT EXCEL BUTTON -->
        <b-button variant="success">
          <i class="fa-solid fa-file-excel mr-50"></i>
          Export Excel
        </b-button>
      </b-card-header>
      <b-card-body>
        <div no-body class="card-top-provinsi">
          <!-- DROPDOWN TOP 10 PROVINCE BUTTON -->
          <b-button
            v-b-toggle.top-10-provinsi
            variant="flat"
            @click="handleVisible()"
          >
            <div class="d-flex">
              <h5 style="color: #000111" class="text">Top Provinsi</h5>
              <div class="ml-auto" style="margin-top: 10px">
                <feather-icon v-if="visible" icon="ChevronUpIcon" />
                <feather-icon v-else icon="ChevronDownIcon" />
              </div>
            </div>
            <div class="d-flex mr-3 text">
              <small>10 Provinsi dengan Mention Terbanyak</small>
            </div>
          </b-button>

          <!-- ITEMS OF TOP 10 PROVINCE -->
          <b-card-body style="margin-top: -40px">
            <b-collapse visible id="top-10-provinsi" style="margin-top: 45px">
              <div
                v-for="provinsi in top_provinsi"
                :key="provinsi.hc_key"
                class="provinsi-select clickable"
                :class="{ active: id_selected == provinsi.hc_key }"
              >
                <div
                  class="d-flex"
                  style="
                    margin-top: -5px;
                    padding-top: 3px;
                    padding-left: 5px;
                    padding-right: 5px;
                  "
                  @click="getProvinsi(provinsi.hc_key)"
                >
                  <div class="h6 text">{{ provinsi.name }}</div>
                  <div class="h6 ml-auto text">{{ provinsi.z }}</div>
                </div>
                <hr style="margin-top: -2px" />
              </div>
            </b-collapse>
          </b-card-body>
        </div>

        <!-- BUBBLE MAPS -->
        <div class="map mb-5">
          <div id="mapcontainer"></div>
        </div>
      </b-card-body>
    </b-card>
    <transition name="block">
      <div id="informasi">
        <!-- AFTER SELECT PROVINCE -->
        <div v-if="id_selected != null" class="row mb-2">
          <div class="col-md-6">
            <!-- AKUN TERPOPULER COMPONENT -->
            <cardAkunTerpopuler :provinsi="selected" />
          </div>
          <div class="col-md-6">
            <!-- UNGGAHAN TERBARU COMPONEN -->
            <cardUnggahanTerbaru :provinsi="selected" />
          </div>
        </div>

        <!-- IF NOT SELECT PROVINCE -->
        <div v-else class="row">
          <div class="col-md-6">
            <!-- ALERT WARNING COMPONENT -->
            <alertWarning
              :title="'Akun Terpopuler'"
              :subtitle="'*Silahkan Pilih Provinsi untuk melihat Akun Terpopuler'"
            />
          </div>
          <div class="col-md-6">
            <!-- ALERT WARNING COMPONENT -->
            <alertWarning
              :title="'Unggahan Terbaru'"
              :subtitle="'*Silahkan Pilih Provinsi untuk melihat Unggahan Terbaru'"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardText,
  BButton,
  BCollapse,
  BAlert,
  VBToggle,
} from "bootstrap-vue";
import $ from "jquery";
import * as Highcharts from "highcharts/highmaps.js";
import map_id from "./idMaps.js";
import "highcharts/themes/grid.js";
Highcharts.maps["countries/id/id-all"] = map_id.load_data();
import cardAkunTerpopuler from "./cardAkunTerpopuler.vue";
import cardUnggahanTerbaru from "./cardUnggahanTerbaru.vue";
import alertWarning from "@/components/usable/alertWarning.vue";

export default {
  name: "bubbleMaps",
  props: ["media", "dataset"],
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardText,
    BButton,
    BCollapse,
    BAlert,
    cardAkunTerpopuler,
    cardUnggahanTerbaru,
    VBToggle,
    alertWarning,
  },

  data() {
    return {
      id_selected: null,
      visible: true,
      selected: {},
      top_provinsi: [],
      provinsi: [
        {
          hc_key: "id-ri",
          name: "Riau",
          z: 9,
        },
        {
          hc_key: "id-kt",
          name: "Kalimantan Tengah",
          z: 1,
        },
        {
          hc_key: "id-sl",
          name: "Sumatera Selatan",
          z: 16,
        },
        {
          hc_key: "id-sg",
          name: "Sulawesi Tenggara",
          z: 2,
        },
        {
          hc_key: "id-jr",
          name: "Jawa Barat",
          z: 37,
        },
        {
          hc_key: "id-kb",
          name: "Kalimantan Barat",
          z: 9,
        },
        {
          hc_key: "id-jt",
          name: "Jawa Tengah",
          z: 51,
        },
        {
          hc_key: "id-sw",
          name: "Sulawesi Utara",
          z: 4,
        },
        {
          hc_key: "id-kr",
          name: "Kepulauan Riau",
          z: 7,
        },
        {
          hc_key: "id-ba",
          name: "Bali",
          z: 19,
        },
        {
          hc_key: "id-ja",
          name: "Jambi",
          z: 1,
        },
        {
          hc_key: "id-ku",
          name: "Kalimantan Utara",
          z: 13,
        },
        {
          hc_key: "id-bb",
          name: "Bangka Belitung",
          z: 26,
        },
        {
          hc_key: "id-ac",
          name: "Aceh",
          z: 15,
        },
      ],
    };
  },

  created() {
    this.sortTopProvinsi();
    this.getTopProvinsi();
  },
  mounted() {
    if (this.data !== null) {
      new Highcharts.mapChart("mapcontainer", this.HC_config(this));
    }
  },

  methods: {
    // GET PROVINCE BY CLICK
    getProvinsi(id) {
      let id_prov = id;
      this.id_selected = id;
      this.selected = this.provinsi.find((el) => el.hc_key == id_prov);
      $([document.documentElement, document.body]).animate({
        scrollTop: $("#informasi").offset().top - 100,
      });
    },
    // SORTING TOP 10 PROVINCE
    sortTopProvinsi() {
      return this.provinsi.sort((a, b) => b.z - a.z);
    },
    // GET TOP 10 PROVINCE
    getTopProvinsi() {
      for (let i = 0; i < 10; i++) {
        this.top_provinsi[i] = this.provinsi[i];
      }
    },
    // VIEW DROPDOWN ITEMS TOP 10 PROVINCE
    handleVisible() {
      if (this.visible === true) {
        this.visible = false;
      } else {
        this.visible = true;
      }
    },
    // BUBBLE MAPS CONFIGURATION
    HC_config: function (nv) {
      var map_config = {
        chart: {
          map: "countries/id/id-all",
          height: 35 + "%",
          backgroundColor: "none",
        },
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
        title: {
          text: "",
        },
        subtitle: {
          // text:'per Komoditas/Provinsi '
        },
        legend: {
          enabled: false,
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: "bottom",
          },
          enableMouseWheelZoom: false,
        },
        series: [
          {
            type: "map",
            name: "Provinsi",
            color: "#E0E0E0",
            nullColor: "#f8f9fa",
            enableMouseTracking: true,
            data: nv.provinsi,
            dataLabels: {
              formatter: function () {
                return (
                  '<span class="point-name" data-id="' +
                  this.point.hc_key +
                  '">' +
                  this.point.name +
                  "</span>"
                );
              },
              enabled: false,
              useHTML: true,
            },
            tooltip: {
              pointFormat: "{point.name} <br/> Jumlah Mentions: {point.z} ",
            },
            cursor: "pointer",
          },
          {
            type: "mapbubble",
            name: "Jumlah Mentions",
            joinBy: "hc_key",
            color: "#d3eeff",
            data: nv.provinsi,
            maxSize: "12%",
            marker: {
              fillOpacity: 1,
            },
            tooltip: {
              pointFormat: "{point.name} <br/> Jumlah Mentions: {point.z} ",
            },
            dataLabels: {
              allowOverlap: true,
              format:
                '<span class="point-name" data-code="{point.hc_key}">{point.name}</span>',
              enabled: true,
            },
            point: {
              events: {
                click: function () {
                  nv.getProvinsi(this.hc_key);
                },
              },
            },
            cursor: "pointer",
            zIndex: 99,
          },
        ],
      };
      return map_config;
    },
  },
  directives: {
    "b-toggle": VBToggle,
  },
};
</script>

<style scoped>
.highcharts-point {
  z-index: 999 !important;
}
.highcharts-text-outline {
  stroke-width: 0px;
  color: #f5ecec;
}
.card-top-provinsi {
  position: absolute;
  box-shadow: 2px 2px 2px 2px rgba(208, 206, 206, 0.8);
  z-index: 2;
  right: 0;
  background-color: transparent;
  margin-right: 20px;
  border-radius: 10px;
}
.dark-layout * .card-top-provinsi {
  background-color: #343b51;
  opacity: 0.8;
}
.dark-layout * .text {
  color: white !important;
}
.provinsi-select :hover {
  background-color: #ecf5f8;
  border-radius: 5px;
}
.dark-layout * .provinsi-select :hover {
  background-color: #202940;
  border-radius: 5px;
}
.active {
  background-color: #d3eeff;
  border-radius: 5px;
}
.dark-layout * .active {
  background-color: #161d31;
}
</style>